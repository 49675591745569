@mixin animation($type, $value){
    -webkit-animation-#{$type}: $value;
       -moz-animation-#{$type}: $value;
        -ms-animation-#{$type}: $value;
         -o-animation-#{$type}: $value;
            animation-#{$type}: $value;
}
@mixin font-face($name, $family, $weight: normal, $style: normal){
    @font-face {
      font-family: $family;
      src: url('../fonts/#{$name}/#{$name}.eot?#iefix') format('embedded-opentype'),  url('../fonts/#{$name}/#{$name}.woff') format('woff'), url('../fonts/#{$name}/#{$name}.ttf')  format('truetype'), url('../fonts/#{$name}/#{$name}.svg#Roboto-Light') format('svg');
      font-weight: $weight;
      font-style: $style;
    }
}

@mixin transition($property, $durations:0.3s, $function: ease-out){
     -moz-transition: $property $durations $function;
     -o-transition: $property $durations $function;
      -webkit-transition: $property $durations $function;
    transition: $property $durations $function; 
}

@mixin rotate($property){
-moz-transform: rotate($property);  /* FF3.5+ */
       -o-transform: rotate($property);  /* Opera 10.5 */
  -webkit-transform: rotate($property);  /* Saf3.1+, Chrome */
      -ms-transform: rotate($property);  /* IE9 */
          transform: rotate($property);  
}
@mixin box-shadow($top, $left, $blur, $vlaue, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $vlaue $color;
        -moz-box-shadow:inset $top $left $blur $vlaue $color;
        box-shadow:inset $top $left $blur $vlaue $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $vlaue $color;
        -moz-box-shadow: $top $left $blur $vlaue $color;
        box-shadow: $top $left $blur $vlaue $color;
    }
}