$color1: #3c3c3b;
$color2: #2f2f2e;
$color3: #222221;
$color4: #ababab;
$color5: #f35958;//red
$color6: #30cbc9;//turkiz
$color7: #e5e5e5;//grey content bg
$color8: #f26b63;//red fooldali doboz
$color9: #f0a04a;//orange fooldali doboz
$color10: #f2f2f2;// tablebuilder egyik sora (a zebrából a sötétebb)
$color11: #2fcc71;// button bg succes
$color12: #919191;// logo szürke
$color13: #2f2f2e ;// menu ul bg 
$color14: #b370ae ;// purple 

@font-face {
    font-family: 'Roboto';
}
