//font készlet (Mindenhol ezt az egy családot használjuk)
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);

@import 'general/mixins';
@import 'general/variables';


@import 'vendor/old_style'; 
@import 'vendor/themeoverride/login';

/*
PIN kódos belépés
url: oktatoterem.wir.hu/login/pin
 */

#frm_login_pin {
  input[type="password"]{
    color : transparent;
    text-shadow : 0 0 0 #000;
    font-size: 20px;
    line-height: 60px;
    height: 60px;
    padding: 0 20px;
    letter-spacing: 20px;
    text-align: center;
  }
  input[type="password"]:focus{
    outline : none;
  }
}

/*
Loader a belépéshez. Lehet, hogy ezt általánosan be kellene tenni és használni
 */

$colors: #F1725D, #38BDAB, #9D30A5, #B779E2, #683893;
$left: 25px;
$count: 40px;
$timing: 600ms infinite ease-in-out;
$delay: 75ms;

@mixin jelly($num1, $num2) {
  stroke: nth($colors, $num1);
  cx: $left + ($count * $num2);
  stroke-width: 3px;
  animation: jump $timing;
  opacity: .7;
  animation-delay: $delay * $num2;
}

@mixin floor($num2) {
  fill: #333333;
  opacity: .05;
  rx: 0;
  ry: 0;
  cx: $left + ($count * $num2);
  cy: 48px;
  animation: shadow $timing;
  animation-delay: $delay * $num2;
}

.contain {
  position: relative;
  margin: 0px auto;
  width: 200px;
}

svg {
  position: absolute;

  ellipse {
    transform-origin: center;
  }

  &:nth-of-type(1) ellipse {
    @include jelly(1, 0);
  }

  &:nth-of-type(2) ellipse {
    @include jelly(2, 1);
  }

  &:nth-of-type(3) ellipse {
    @include jelly(3, 2)
  }

  &:nth-of-type(4) ellipse {
    @include jelly(4, 3)
  }

  &:nth-of-type(5) ellipse {
    @include jelly(5, 4)
  }

  &:nth-of-type(6) ellipse {
    @include floor(0)
  }

  &:nth-of-type(7) ellipse {
    @include floor(1)
  }

  &:nth-of-type(8) ellipse {
    @include floor(2)
  }

  &:nth-of-type(9) ellipse {
    @include floor(3)
  }

  &:nth-of-type(10) ellipse {
    @include floor(4)
  }
}

$stroke-reg: 3px;
$dist: 10px;

@keyframes jump {
  40% {
    transform: translateY($dist * 2) scale(1.3);
    opacity: .9;
  }
  40% {
    rx: $dist;
    ry: $dist;
    stroke-width: $stroke-reg;
  }
  45% {
    rx: $dist + 5;
    ry: $dist - 3;
    stroke-width: $stroke-reg + 1;
  }
  55% {
    rx: $dist;
    ry: $dist;
  }
}

@keyframes shadow {
  45% {
    opacity: .15;
    rx: $dist;
    ry: $dist - 7;
    transform: translateY($dist - 5) scale(1.3);
  }
}