body, body.error-body{
	*{font-family: Roboto;}
    background-color: $color6 !important;
    background: url('../images/login/admin_bgr_image.jpg') no-repeat;
    color: #828997;
    font-family: Roboto;
    font-weight: 300;
}
.logo_container{
    position: fixed;
    top: 20px;
    left: 20px;
}
.box-pusher{
    min-height: 140px;
    height: 13%;
}
.login-container{
    margin-top: 0;
}
.login-container .around-header{
    padding: 40px 70px 0 70px;
}
.login-container .around-header > h2{
    margin-top: 7px;
    color: #4b566a;
    font-size: 36px;
    font-family: Roboto;
    font-weight: 300;
}
.login-container .around-header > h3{
    margin-bottom: 37px;
}
.login-container .around-header > p{
    margin: 0;
    color: #828997;
    font-size: 14px;
    margin-left: 3px;
    padding-bottom: 23px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 22px;
}
.login-container > div{
    border: 1px solid #beefee;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
}
.login-container .withflap{
    display: block;
    width: 100%;
    height: auto;
}
.login-body{
    padding: 45px 65px 29px 65px;
    background: #eeeeee;
}
.form-row{
    padding-top: 5px;
    padding-bottom: 1px;
}
input[type=text],
input[type=password]{
    color: #828997;
    min-height: 0;
    height: 33px;
    font-size: 14px;
    line-height: 33px;
    font-family: Roboto;
    font-weight: 300;
    padding: 0 11px !important;
}
button{
    font-family: Roboto;
    font-weight: 300;
}
.control-group{
    padding-left: 16px;
}
.checkbox{
    padding-top: 5px;
}
.checkbox label{
    padding-left: 28px;
    font-weight: lighter;
    font-size: 14px;
    margin-left: 4px;
}
.checkbox label:before{
    border: none;
}

.checkbox.check-success input[type=checkbox]:checked + label:before{
    background-color: #2baeac;
    border: 1px solid #2baeac;
}
#login{
	float: right;
    margin-top: -5px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 300;
    margin-right: 0;
    background: $color6;
    text-transform: capitalize;
    padding: 7px 9px;
    @include transition(all,0.3s,ease);
    &:hover {
		background: #2baeac;
	}

	
}
.forgottenpassword{
    display: inline-block;
}
.remembercb{
    display: inline-block;
    max-width: 130px;
}
body, div, span, p, h2, input, button{
    font-family: Roboto;
    font-weight: 300;
}
a{
    font-size: 14px;
    color: #236bcc;
}
@media (max-width: 767px){
    .login-container .around-header{
        padding: 20px;
    }
    .login-container .around-header > p{
        padding-bottom: 10px;
    }
    .login-body{
        padding: 20px;
    }
    .checkbox{
        text-align: center;
    }
    #login{
        width: 100%;
        float: none;
    }
    .forgottenpassword{
    }
    .remembercb{
        width: 100%;
    }
}